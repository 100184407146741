<template>
    <div class="container d-flex container--fluid pa-0 ma-0 ">
        <div class="d-flex align-start">
            <router-link to="/homepage">
                <v-img style="margin: 0 70px !important;min-width:120px; width: 120px;" alt="TurismoBC Logo"
                       class="shrink mt-1 hidden-sm-and-down"
                       contain
                       src="@/assets/images/logo-bhc.png"
                       />
            </router-link>
        </div>

        <v-btn class="ma-1"
               color="#631333"
               height="70px !important"
               plain
               text
               to="/hospitals-clinics">
            <span>Hospitales y Clínicas</span>
        </v-btn>
        <v-btn
            class="ma-1"
            color="#631333"
            height="70px !important"
            plain
            text
            to="/medical-services"> <!-- #services -->
            <span>Servicios Médicos</span>
        </v-btn>

        <v-menu class="hidden-md-and-up" offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ma-1"
                    color="#631333"
                    height="70px !important"
                    plain
                    text
                    to="/other-services">
                    <span>Otros Servicios</span>
                </v-btn>
            </template>

            <v-list v-model="selectedItemService" dense>
                <template v-for="(item, index) in itemsService">
                    <v-divider
                        v-if="item.divider"
                        :key="index"
                        :inset="item.inset">
                    </v-divider>
                    <v-list-item
                        v-else
                        :key="item.title"
                        :to="item.link">
                        <v-icon color="#631333" height="70px !important" v-text="item.icon"></v-icon>
                        <v-list-item-action>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>

        <v-menu class="hidden-md-and-up" offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ma-1"
                    color="#631333"
                    height="70px !important"
                    plain
                    text>
                    <span>Nosotros</span>
                </v-btn>
            </template>

            <v-list v-model="selectedItemAboutUs" dense>
                <template v-for="(item, index) in itemsAbout">
                    <v-divider
                        v-if="item.divider"
                        :key="index"
                        :inset="item.inset">
                    </v-divider>
                    <v-list-item
                        v-else
                        :key="item.title"
                        :to="item.link">
                        <v-icon color="#631333" height="70px !important" v-text="item.icon"></v-icon>
                        <v-list-item-action>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>

        <v-btn class="ma-1"
               color="#631333"
               height="70px !important"
               plain
               text
               to="/blogs"> <!-- #blogs -->
            <span>Blogs</span>
        </v-btn>

        <search-box-component class="hidden-md-and-down"/>

        <v-spacer></v-spacer>

        <!--<v-menu class="hidden-md-and-up" offset-y v-show="false">
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-translate
                </v-icon>
            </template>

            <v-list dense v-model="selectedLang">
                <v-list-item
                        :key="index"
                        :to="lang.link"
                        router
                        v-for="(lang, index) in langItems">
                    <v-list-item-action>
                        <v-list-item-title v-text="lang.title"></v-list-item-title>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-menu>-->

        <v-btn icon to="/authentication">
            <v-icon>mdi-account-circle</v-icon>
        </v-btn>

    </div>

</template>

<script>
    import { menuItemsService, menuItemsAbout, menuItemsLang } from "@/layouts/app-layout/menu/MenuItems.js";
    import SearchBoxComponent from "@/components/search-box/SearchBoxComponent";

    export default {
        name: "HorizontalMenu",
        components: { SearchBoxComponent },
        data: () => ({
            selectedItemService: 1,
            selectedItemAboutUs: 1,
            selectedLang: 1,
            itemsService: menuItemsService,
            itemsAbout: menuItemsAbout,
            langItems: menuItemsLang
        })
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>
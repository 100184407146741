<template>
    <v-app id="startpage">
        <menu-component/>
        
        <!-- Sizes your content based upon application components -->
        <v-main>
            <v-container fluid style="margin: 0px; padding: 0px">
                <v-btn
                        v-scroll="onScroll"
                        v-show="fab"
                        color="primary"
                        class="mb-8"
                        dark
                        absolute
                        fixed
                        bottom
                        right
                        fab
                        small
                        @click="toTop">
                    <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                
                <!-- If using vue-router -->
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import MenuComponent from "@/layouts/app-layout/menu/MenuComponent.vue"
    // import 'vuetify/dist/vuetify.min.css'

    export default {
        name: 'Layout',
        components: {
            MenuComponent,
        },
        data: () => ({
            fab: false,
        }),
        methods: {
            onpageshow() {
                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
            },
            onScroll(e) {
                if (typeof window === "undefined") return;
                const top = window.pageYOffset || e.target.scrollTop || 0;
                this.fab = top > 20;
            },
            toTop() {
                /*  window.scrollTo({ top: 0, left: 0, behavior: "smooth", }); */
                this.$vuetify.goTo(0);
            },
        },
    };
</script>

<style scoped lang="sass">
    @import '~vuetify/src/styles/generic/reset'
    // @import '~vuetify/src/styles/generic/index';

</style>
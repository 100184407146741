/***********************************************/
// Capa de Servicios para el modulo de Ciudades.

import axios from "axios";
import store from "@/store/store.js";

export default new class {
    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async search(value) {
        let response = await axios.get(`${this.#baseUrl}/search?value=${value}`);
        // console.log("response:", response);
        if (response.data.success) {
            console.log("response.data:", response.data);
            return response.data;
        } else {
            return null;
        }
    }
}
<template>
    <v-responsive style="margin-top: 1% !important; max-width:200px;" >
        <v-text-field
            id="findtext-menu"
            v-model="searchText"
            clearable
            dense
            flat
            hide-details
            label="Buscar"
            name="find"
            outlined
            placeholder="Buscar"
            prepend-inner-icon="mdi-magnify"
            single-line
            @keydown.enter="enterSearch"
            @click:clear="clearTextSearch"
            @click:prepend-inner="enterSearch">
        </v-text-field>
    </v-responsive>
</template>

<script>
    import searchService from "@/providers/SearchService";
    import { mapMutations } from "vuex";

    export default {
        name: "SearchBoxComponent",
        data: () => ({
            searchText: null
        }),
        methods: {
            toTop() {
                this.$vuetify.goTo(0);
            },
            invokeSearch(value) {
                searchService.search(value).then(record => {
                    this.SetResultSearch(record.result);
                });
            },
            enterSearch(event) {
                console.log(event);
                this.SetSearchText(this.searchText);
                this.$store.commit('ShowSearchPanel');
                this.$store.dispatch('SearchText');
                this.searchText = null;
                this.toTop();
                /*.then(response => {
                    console.log('response', response);
                    console.log('store.state.resultSearch', store.getters.getResultSearch);
                });*/
            },
            clearTextSearch() {
                this.searchText = null;
                this.$store.commit('HideSearchPanel');
                this.$store.commit('ClearSearchText');
                this.$store.commit('ClearResultSearch');
            },
            ...mapMutations(['SetResultSearch', 'SetSearchText'])
        }
    }
</script>

<style scoped>

</style>